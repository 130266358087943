import { Box, Card, CardContent, Typography } from '@mui/material'
import Image from 'next/image'

import { STRINGS } from '@/constants'

export default function Custom404() {
  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold' }} marginTop="30px">
        {STRINGS.PAGE_NOT_FOUND_MESSAGE}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        {STRINGS.UNABLE_FIND_PAGE_VERIFY_URL_MESSAGE}
      </Typography>
      <Card sx={{ maxWidth: 345 }}>
        <CardContent>
          <Image
            width="300"
            height="450"
            alt="sad dog card"
            src="https://img.comc.com/i/Non-Sports/1971/Burgerbits-Dog-Food---Base/22/English-Springer-Spaniel.jpg?id=8aa04269-acbe-4f49-94c4-6cf9428ab12a&size=original"
          />
        </CardContent>
      </Card>
    </Box>
  )
}
